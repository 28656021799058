import React from "react";
import InputComponent from "../Component/inputComponent.js";
class InserisciAzienda extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disableInput: false,
            check: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        };
        this.changeValue = this.changeValue.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.inviaDati = this.inviaDati.bind(this);
        this.checkBackend = this.checkBackend.bind(this);

    }
    async checkAll() {
        let test = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
        if (this.state.nome_azienda == null) {
            test[0] = true;
        }
        if (this.state.slang == null) {
            test[1] = true;
        }
        if (this.state.cod_univoco_fatturazione == null) {
            test[2] = true;
        }
        let pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (this.state.email == null || !pattern.test(String(this.state.email).toLowerCase())) {
            test[3] = true;
        }
        if (this.state.pec == null) {
            test[4] = true;
        }
        if (this.state.nome_responsabile == null) {
            test[5] = true;
        }
        if (this.state.cognome_responsabile == null) {
            test[6] = true;
        }
        if (this.state.telefono_responsabile == null) {
            test[7] = true;
        }

        if (this.state.email_responsabile == null || !pattern.test(String(this.state.email_responsabile).toLowerCase())) {
            test[8] = true;
        }
        if (this.state.password == null) {
            test[9] = true;
        }
        if (this.state.via == null) {
            test[10] = true;
        }
        if (this.state.cap == null) {
            test[11] = true;
        }
        if (this.state.nazione == null) {
            test[12] = true;
        }
        if (this.state.url_logo == null) {
            test[13] = true;
        }
        if (this.state.tag_disponibili == null) {
            test[15] = true;
        }
        if (this.state.data_inizio_contratto == null) {
            test[16] = true;
        }
        if (this.state.data_scadenza_contratto == null) {
            test[17] = true;
        }
        await this.setState({ check: test });

    }

    checkBackend() {
        for (let i = 0; i < this.state.check.length; i++) {
            if (this.state.check[i]) {
                return false;
            }
        }
        return true;
    }
    async inviaDati() {
        await this.checkAll();

        //if (!this.checkBackend()) {
        if (this.checkBackend()) {
            var data = new FormData();
            const payload = {
                nome_azienda: this.state.nome_azienda,
                slang: this.state.slang,
                cod_univoco_fatturazione: this.state.cod_univoco_fatturazione,
                email: this.state.email,
                pec: this.state.pec,
                nome_responsabile: this.state.nome_responsabile,
                cognome_responsabile: this.state.cognome_responsabile,
                telefono_responsabile: this.state.telefono_responsabile,
                email_responsabile: this.state.email_responsabile,
                password: this.state.password,
                via: this.state.via,
                cap: this.state.cap,
                nazione: this.state.nazione,
                url_logo: this.state.url_logo,
                progetti: this.state.progetti || 1,
                assistenza: this.state.assistenza || 0,
                tag_disponibili: this.state.tag_disponibili,
                prodotti: this.state.prodotti,
                data_inizio_contratto: this.state.data_inizio_contratto,
                data_scadenza_contratto: this.state.data_scadenza_contratto
            };
            data.append("datiRegistrazione", JSON.stringify(payload));
            let result = await fetch(process.env.REACT_APP_BACKEND + '/datiGestionale.php', {
                method: 'POST',
                body: data,
                mode: "cors",
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            }).then(async function (response) {
                return await response.json();
            })
            if (result.stato == "success") {
                this.setState({ disableInput: true })
                this.setState({ messaggioSuccess: "Azienda inserita correttamente" });
                this.setState({ messaggioError: "" });
            }
            else {
                this.setState({ messaggioError: "Errore nel inserimento" });
            }
        }
    }
    changeValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        return (
            <div className="inserisci-azienda-div animated slideInRight">
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci il nome dell'azienda" check={this.state.check[0]} name="nome_azienda" title="Nome Azienda" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci lo Slang" check={this.state.check[1]} name="slang" title="Slang" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci il numero di progetti disponibili" name="progetti" title="Progetti disponibili" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci il valore di assistenza" name="assistenza" title="Assistenza (0 non attiva, 1 attiva)" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci Codice univoco fatturazione" check={this.state.check[2]} name="cod_univoco_fatturazione" title="Codice univoco fatturazione" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci Email" check={this.state.check[3]} name="email" title="Email" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci Pec" check={this.state.check[4]} name="pec" title="Pec" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci Nome Responsabile" check={this.state.check[5]} name="nome_responsabile" title="Nome Responsabile" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci Cognome Responsabile" check={this.state.check[6]} name="cognome_responsabile" title="Cognome Responsabile" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci Telefono Responsabile" check={this.state.check[7]} name="telefono_responsabile" title="Telefono Responsabile" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci Email Responsabile" check={this.state.check[8]} name="email_responsabile" title="Email Responsabile" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci Password" check={this.state.check[9]} name="password" title="Password" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci la Via" check={this.state.check[10]} name="via" title="Via" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci il CAP" check={this.state.check[11]} name="cap" title="CAP" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci la Nazione" check={this.state.check[12]} name="nazione" title="Nazione" changeValue={this.changeValue}></InputComponent>
                <InputComponent Disabled={this.state.disableInput} placeHolder="Inserisci il Logo" check={this.state.check[13]} name="url_logo" title="Logo" changeValue={this.changeValue}></InputComponent>
                <label className="title-slum-tally" className="title-slum-tally">Data inizio contratto</label><br />
                <input disabled={this.state.disableInput} onChange={this.changeValue} type="date" name="birthday" name="data_inizio_contratto" /><br />
                <label className="title-slum-tally" className="title-slum-tally">Data fine contratto</label><br />
                <input disabled={this.state.disableInput} onChange={this.changeValue} type="date" name="birthday" name="data_scadenza_contratto" />
                <InputComponent Number={true} Disabled={this.state.disableInput} placeHolder="Inserisci i Tag Disponibili" check={this.state.check[15]} name="tag_disponibili" title="Tag Disponibili" changeValue={this.changeValue}></InputComponent>
                <span className="messaggio-errore-slum-tally">{this.state.messaggioError}</span>
                <span className="messaggio-successo-slum-tally">{this.state.messaggioSuccess}</span>
                <div class=" row send-button">
                    <div class="col-sm-3">
                        <button disabled={this.state.disableInput} className="send-btn" onClick={this.inviaDati}>INVIA</button>
                    </div>
                </div>

            </div>
        );
    }
}

export default InserisciAzienda;
