import React from "react";
import Richiesta from "../Component/Richiesta.js";
import Chat from "../Component/Chat.js";

class Supporto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            check_visualizza: false,
            check_modifica: false,
            disableInput: false,
            textareaMessage: '',
            messaggi: [],
        };

        this.visualizza = this.visualizza.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.leggiDati = this.leggiDati.bind(this);
        this.settaAziende = this.settaAziende.bind(this);
        this.ricerca = this.ricerca.bind(this);
        this.getMessaggio = this.getMessaggio.bind(this);
        this.createMessage = this.createMessage.bind(this);
    }
    changeValue(e) {
        this.setState({ textareaMessage: e.target.value });
    }
    async createMessage() {
        if (this.state.textareaMessage.length < 5) { return false; }
        var data = new FormData();
        const payload = {
            textareaMessage: this.state.textareaMessage,
            id_richiesta: this.state.richiestaSelezionata.ID_RICHIESTA,
        };
        data.append("datiMessaggio", JSON.stringify(payload));
        let result = await fetch(process.env.REACT_APP_BACKEND + '/inserisciMessaggioRichiesta.php', {
            method: 'POST',
            body: data,
            mode: "cors",
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        })
        if (result.stato == "success") {
            result = JSON.parse(result.messaggio);
            await this.setState({ messaggi: result });
        }
        else {
            this.setState({ messaggioError: result.messaggio });
        }
        await this.setState({ textareaMessage: '' });
        await this.getMessaggio();
    }
    async getMessaggio() {
        var data = new FormData();
        const payload = {
            id_richiesta: this.state.richiestaSelezionata.ID_RICHIESTA
        };
        data.append("datiRichieste", JSON.stringify(payload));
        let result = await fetch(process.env.REACT_APP_BACKEND + '/getMessaggio.php', {
            method: 'POST',
            body: data,
            mode: "cors",
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        }).then(async function (response) {
            return await response.json();
        })
        if (result.stato == "success") {
            result = JSON.parse(result.messaggio);
            await this.setState({ messaggi: result });
            this.setState({ loader: false });
        }
        else {
            this.setState({ messaggioError: result.messaggio });
        }
    }
    async visualizza(codice_richiesta) {
        for (let i = 0; i < this.state.richiesteDB.length; i++) {
            if (this.state.richiesteDB[i].CODICE_RICHIESTA == codice_richiesta) {
                await this.setState({ richiestaSelezionata: this.state.richiesteDB[i] });
                break;
            }
        }
        await this.setState({
            check_visualizza: true
        })
        await this.getMessaggio();
    }
    async settaAziende(result) {
        let aziende = [];
        result.forEach(azienda => {
            aziende.push(<Richiesta visualizza={this.visualizza} datiAzienda={azienda} />);
        });
        this.setState({ aziende: aziende });
    }
    async leggiDati() {
        let result = await fetch(process.env.REACT_APP_BACKEND + '/AllRichieste.php', {
            method: 'POST',
            mode: "cors",
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        }).then(function (response) {
            return response.json();
        })
        if (result.stato == "success") {
            result = JSON.parse(result.messaggio);
            this.setState({ richiesteDB: result, loader: false });
            this.settaAziende(result);
        }
    }
    async ricerca(e) {
        let r;
        if (e.target.value == "") {
            r = this.state.richiesteDB;
        }
        else {
            r = [];
            for (let i = 0; i < this.state.richiesteDB.length; i++) {
                if (this.state.richiesteDB[i].NOME_AZIENDA.toUpperCase().includes(e.target.value.toUpperCase())) {
                    r.push(this.state.richiesteDB[i]);
                }
            }
        }
        this.settaAziende(r);
    }
    async componentDidMount() {
        this.leggiDati();
    }
    render() {

        if (this.state.loader) {
            return <img src={process.env.PUBLIC_URL + '/loader1.gif'}></img>
        }
        else {
            return (
                <div className="row lista-nomi-aziende">
                    <div className="col-6 animated slideInRight" style={{maxHeight:"100vh",overflow:"scroll"}}>
                        <input onChange={this.ricerca} className="cerca-azienda-input" placeholder="Cerca il nome dell'azienda"></input>
                        {this.state.aziende}
                    </div>
                    {this.state.check_visualizza &&
                        <div className="col-6 lista-dati-aziende animated slideInRight">
                            <Chat richiesta={this.state.richiestaSelezionata} cambiaValoreMessaggio={this.changeValue} textareaMessage={this.state.textareaMessage} createMessage={this.createMessage} messaggi={this.state.messaggi}></Chat>
                        </div>}

                    {this.state.loader &&
                        <img className="loader-chat" src={process.env.PUBLIC_URL + '/loader1.gif'}></img>
                    }
                </div>
            );
        }
    }
}
export default Supporto;
