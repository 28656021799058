import React from "react";
export default class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = { template: null, loading: true };
        this.cambiaStatoTemplate = this.cambiaStatoTemplate.bind(this);
    }

    componentDidMount() {
        this.caricaTemplate();
    }

    cambiaStatoTemplate = async (stato, id_template) => {
        this.setState({ loading: true });
        var data = new FormData();
        const payload = {
            STATO: stato,
            ID_TAG: id_template,
        };
        data.append("modificaTemplate", JSON.stringify(payload));
        let result = await fetch(process.env.REACT_APP_BACKEND + '/modificaStatoTemplate.php', {
            method: 'POST',
            body: data,
            mode: "cors",
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        }).then(async function (response) {
            return await response.json();
        })
        if (result.stato == "success") {
            this.caricaTemplate();
            this.setState({ loading: false });
        }
        else {
            alert("Errore nel cambio di stato")
            this.setState({ loading: false });
        }
    }

    caricaTemplate = async () => {
        let result = await fetch(process.env.REACT_APP_BACKEND + '/getTemplates.php', {
            method: 'POST',
            mode: "cors",
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        }).then(function (response) {
            return response.json();
        })
        if (result.stato != "error") {
            result = JSON.parse(result.messaggio);
            this.setState({ template: result, loading: false });
        }
    }
    render() {
        return (
            <div>
                {this.state.loading && <img src={process.env.PUBLIC_URL + '/loader1.gif'}></img>}
                <div className="lista-aziende" style={this.state.loading ? { display: "none" } : {}}>
                    {this.state.template && this.state.template.map(temp => {
                        return (
                            <div className="container-nomi-azienda">
                                <span className="nome-azienda"><b>Nome Azienda:</b><br></br>{temp?.NOME_AZIENDA}</span><br></br>
                                <span className="nome-azienda"><b>Slang: </b><br></br>{temp?.SLANG_AZIENDA}</span><br></br>
                                <span className="nome-azienda"><b>Nome progetto: </b><br></br>{temp?.NOME_PRODOTTO}</span>
                                <br />
                                {parseInt(temp.STATO) !== 0 ?
                                    <button style={{ backgroundColor: "gray", border: "1px solid gray" }} onClick={() => { this.cambiaStatoTemplate(0, temp.ID_TAG) }} className="visualizza-btn gray">Disattiva</button>
                                    : <button style={{ backgroundColor: "green", border: "1px solid green" }} onClick={() => { this.cambiaStatoTemplate(1, temp.ID_TAG) }} className="visualizza-btn green">Attiva</button>
                                }<br></br>
                                <a target="_blank" href={`https://x.tally-tap.com/${temp?.SLANG_AZIENDA}/${temp?.ID_TAG}/1`}><button className="visualizza-btn">Visualizza</button></a>
                            </div>
                        )
                    })
                    }
                </div>
            </div >
        )
    }
}