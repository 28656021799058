import React from "react";
import SendMessage from "./SendMessage.js";
import ReceivedMessage from "./ReceivedMessage.js";
function Chat(props) {
    return (
        <div className="row row-chat">
            <div className="col-12">
                <div className="content-support-chat">
                    {props.messaggi.map((messaggio, indice) => {
                        if (messaggio.RICEVUTO == 0) {
                            return <ReceivedMessage key={"message-" + indice} richiesta={props.richiesta} messaggio={messaggio}></ReceivedMessage>
                        }
                        else if (messaggio.RICEVUTO == 1) {
                            return <SendMessage key={"message-" + indice} richiesta={props.richiesta} messaggio={messaggio}></SendMessage>
                        }

                    })}
                </div>
            </div>
            <div className="col-12">
                <div>
                    <div className="text-area-support-chat">
                        <span className="bold">Nuovo Messaggio:</span><br></br>
                        <textarea value={props.textareaMessage} onChange={props.cambiaValoreMessaggio} className="textarea-slum" placeholder="Scrivi qui il tuo messaggio" name="supporto_descrizione"> </textarea>
                    </div>
                </div>
                <div >
                    <button className="visualizza-btn-chat" onClick={props.createMessage}>Invia Messaggio</button>
                </div>
            </div>
        </div>
    );
}
export default Chat;
