import React from "react";

function ViewOrdine(props) {
    return (
        <section>
            <p className="campi-visualizza-dati" className="campi-visualizza-dati">{props.NomeCampo}</p>
            <span className="value-visualizza-dati">{props.ValoreCampo}</span>
        </section>
    );
}
export default ViewOrdine;