import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginEseguito: false,
            loader: false,
            email: "",
            password: "",
            login: false
        };
        this.cambiaValore = this.cambiaValore.bind(this);
        this.eseguiLogin = this.eseguiLogin.bind(this);
    }
    cambiaValore(event) {
        if (event.target.name == "email") {
            this.setState({ email: event.target.value });
        }
        if (event.target.name == "password") {
            this.setState({ password: event.target.value });
        }
    }
    async eseguiLogin() {

        var data = new FormData();
        const payload = {
            email: this.state.email,
            password: this.state.password,
        };
        data.append("datiMessaggio", JSON.stringify(payload));
        let result = await fetch(process.env.REACT_APP_BACKEND + '/login.php', {
            method: 'POST',
            body: data,
        }
        ).then(res => res.json()).then(result => result);
        if (result?.stato == "success") {
            localStorage.setItem("token", result.messaggio);            
            this.setState({ loginEseguito: true });
            this.setState({ loginError: false });
        }
        else if (result?.stato == "error") {
            this.setState({ loginError: true });
            localStorage.removeItem("token");
        }
        if (localStorage.getItem("token")) {
            this.setState({ login: true });
        }
    }
    render() {
        if(this.state.loginEseguito){
            return <Redirect to="/"></Redirect>
        }
        if (this.state.loader == true) {
            return ("<p>loading</p>");
        }
        return (
            <div className="container h-100" id="login-container">
                <div className="row h-100 justify-content-center align-items-center" style={{ maxWidth: "400px", margin: "0 auto" }}>
                    <img
                        src={process.env.PUBLIC_URL + '/logo_definitivo.png'}
                        className="logo-login"
                    ></img>
                    <p className="testo-tally-viola">Email</p>
                    <input
                        type="email"
                        onChange={this.cambiaValore}
                        className="input-tally"
                        name="email"
                        placeholder="e-mail"
                    />
                    <p className="testo-tally-viola">Password</p>
                    <input
                        type="password"
                        className="input-tally"

                        onChange={this.cambiaValore}
                        name="password"
                        placeholder="password"
                    />
                    <button className="visualizza-btn" onClick={this.eseguiLogin}>
                        Login
                    </button>
                    {this.state.loginError && (
                        <p className="error-login">
                            Errore email o password sbagliate
                        </p>
                    )}
                </div>


            </div>
        );
    }
}