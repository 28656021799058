import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./Page/Login.js";
import AppHome from "./AppHome.js";


function App() {
  return (
    <div className="container-slum">
      <Router>
        <AppHome />
        <Route exact path="/login" component={Login} />
      </Router>
    </div >
  );
}
export default App;
