import React from "react";
function ListaAziende(props) {
    return (
        <div className="container-nomi-azienda">
            <img style={{width:"55px",marginRight:"15px"}} src={props.datiAzienda.URL_LOGO} /><br/>
            <span className="nome-azienda"><b>Nome Azienda:</b><br></br>{props.datiAzienda.NOME_AZIENDA}</span><br></br>
            <span className="nome-azienda"><b>Email: </b><br></br>{props.datiAzienda.EMAIL}</span>
            <br></br>
            <button className="visualizza-btn" onClick={() => { props.visualizza(props.datiAzienda.ID_AZIENDA) }}>Visualizza</button>
            <button className="modifica-btn" onClick={() => { props.modifica(props.datiAzienda.ID_AZIENDA) }}>Modifica Dati</button>
        </div>
    );
}
export default ListaAziende;
