import React from "react";
import ListaAziende from "../Component/ListaAziende.js";
import { BrowserRouter as Route, Link, Redirect } from "react-router-dom";
import ViewCampoAzienda from "../Component/ViewCampoAzienda.js";
import InputCampo from "../Component/InputCampo.js";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            check_visualizza: false,
            check_modifica: false,
            disableInput: false,
            error_login: false,
        };
        this.modifica = this.modifica.bind(this);
        this.visualizza = this.visualizza.bind(this);
        this.inviaDati = this.inviaDati.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.changeValueBackend = this.changeValueBackend.bind(this);
        this.leggiDati = this.leggiDati.bind(this);
        this.settaAziende = this.settaAziende.bind(this);
        this.ricerca = this.ricerca.bind(this);
    }
    componentDidMount() {
        if (document.getElementById("login-container")?.length) {
            window.location.reload();
        }
        if(document.getElementById("menu-sx")?.length){
            window.location.reload();
        }
        this.leggiDati();
    }
    changeValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    changeValueBackend(event) {
        this.setState({
            datiBack: {
                ...this.state.datiBack,
                [event.target.name]: event.target.value
            }
        });
    }
    async inviaDati() {
        var data = new FormData();
        const payload = {
            dati: this.state.datiBack
        };
        data.append("datiRegistrazione", JSON.stringify(payload));
        let result = await fetch(process.env.REACT_APP_BACKEND + '/modificaDatiAzienda.php', {
            method: 'POST',
            body: data,
            mode: "cors",
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }).then(async function (response) {
            return await response.json();
        })
        if (result.stato == "SI") {
            this.leggiDati();
            this.setState({ disableInput: true })
            this.setState({ messaggioSuccess: result.messaggio });
            this.setState({ messaggioError: "" });
        }
        else {
            this.setState({ messaggioError: result.messaggio });
        }
    }
    async modifica(id_azienda) {
        for (let i = 0; i < this.state.aziendeDB.length; i++) {
            if (this.state.aziendeDB[i].ID_AZIENDA == id_azienda) {
                await this.setState({ aziendaSelezionata: this.state.aziendeDB[i], datiBack: this.state.aziendeDB[i] });
                break;
            }
        }
        await this.setState({
            check_modifica: true,
            check_visualizza: false
        })
    }
    async visualizza(id_azienda) {
        for (let i = 0; i < this.state.aziendeDB.length; i++) {
            if (this.state.aziendeDB[i].ID_AZIENDA == id_azienda) {
                await this.setState({ aziendaSelezionata: this.state.aziendeDB[i] });
                break;
            }
        }
        await this.setState({
            check_modifica: false,
            check_visualizza: true
        })
    }
    async settaAziende(result) {
        let aziende = [];
        result.forEach(azienda => {
            aziende.push(<ListaAziende visualizza={this.visualizza} modifica={this.modifica} datiAzienda={azienda} />);
        });
        this.setState({ aziende: aziende });
    }
    async leggiDati() {
        console.log(localStorage.getItem("token"));

        let result = await fetch(process.env.REACT_APP_BACKEND + '/AllDB.php', {
            method: 'POST',
            mode: "cors",
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        }).then(function (response) {
            return response.json();
        })
        if (result.stato != "error") {
            result = JSON.parse(result.messaggio);
            this.setState({ aziendeDB: result, loader: false });
            this.settaAziende(result);
        }
        else {
            console.log(result);
            this.setState({ error_login: true, loader: false });
        }
    }
    async ricerca(e) {
        let resultRicerca;
        if (e.target.value == "") {
            resultRicerca = this.state.aziendeDB;
        }
        else {
            resultRicerca = [];
            for (let i = 0; i < this.state.aziendeDB.length; i++) {
                if (this.state.aziendeDB[i].NOME_AZIENDA.toUpperCase().includes(e.target.value.toUpperCase())) {
                    resultRicerca.push(this.state.aziendeDB[i]);
                }
            }
        }
        this.settaAziende(resultRicerca);
    }
    render() {
        return this.state.loader ? <img src={process.env.PUBLIC_URL + '/loader1.gif'}></img> : (<div className="row lista-nomi-aziende ">
            {this.state.error_login && <Redirect to="/login" />}
            <div className="col-12 col-sm-6 animated slideInRight">
                <input onChange={this.ricerca} className="cerca-azienda-input" placeholder="Cerca il nome dell'azienda"></input>
                <div className="lista-aziende">
                    {this.state.aziende}
                </div>
                <Link to="/inserisci-azienda"><button className="add-btn">+</button></Link>
            </div>
            {
                this.state.check_visualizza &&
                <div className="col-12 col-sm-6 lista-dati-aziende animated slideInRight">
                    <ViewCampoAzienda NomeCampo="Nome Azienda" ValoreCampo={this.state.aziendaSelezionata.NOME_AZIENDA}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Slang" ValoreCampo={this.state.aziendaSelezionata.SLANG}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Codice Univoco Fatturazione" ValoreCampo={this.state.aziendaSelezionata.COD_UNIVOCO_FATTURAZIONE}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Email" ValoreCampo={this.state.aziendaSelezionata.EMAIL}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="PEC" ValoreCampo={this.state.aziendaSelezionata.PEC}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Nome Responsabile" ValoreCampo={this.state.aziendaSelezionata.NOME_RESPONSABILE}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Cognome Responsabile" ValoreCampo={this.state.aziendaSelezionata.COGNOME_RESPONSABILE}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Telefono Responsabile" ValoreCampo={this.state.aziendaSelezionata.TELEFONO_RESPONSABILE}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Email Responsabile" ValoreCampo={this.state.aziendaSelezionata.EMAIL_RESPONSABILE}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Via" ValoreCampo={this.state.aziendaSelezionata.VIA}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="CAP" ValoreCampo={this.state.aziendaSelezionata.CAP}></ViewCampoAzienda>
                    <p className="campi-visualizza-dati">Logo</p>
                    <img className="logo-view-campo-azienda" src={this.state.aziendaSelezionata.URL_LOGO}></img>
                    <ViewCampoAzienda NomeCampo="Nazione" ValoreCampo={this.state.aziendaSelezionata.NAZIONE}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Stato" ValoreCampo={this.state.aziendaSelezionata.STATO}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Limite View" ValoreCampo={this.state.aziendaSelezionata.LIMITE_VIEW}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Tag Disponibili" ValoreCampo={this.state.aziendaSelezionata.TAG_DISPONIBILI}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Prodotti Disponibili" ValoreCampo={this.state.aziendaSelezionata.PRODOTTI_DISPONIBILI}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Data Scadenza Contratto" ValoreCampo={this.state.aziendaSelezionata.DATA_SCADENZA_CONTRATTO}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Data Inizio Contratto" ValoreCampo={this.state.aziendaSelezionata.DATA_INIZIO_CONTRATTO}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Codice Invito" ValoreCampo={this.state.aziendaSelezionata.CODICE_INVITO}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Pagato" ValoreCampo={this.state.aziendaSelezionata.PAGATO}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Id Azienda Tag" ValoreCampo={this.state.aziendaSelezionata.ID_AZIENDA_TAG}></ViewCampoAzienda>
                    <ViewCampoAzienda NomeCampo="Id Azienda" ValoreCampo={this.state.aziendaSelezionata.ID_AZIENDA}></ViewCampoAzienda>
                </div>
            }
            {
                this.state.check_modifica &&
                <div className="col-12 col-sm-6 lista-dati-aziende animated slideInRight">
                    <InputCampo NomeCampo="Nome Azienda" disabled={false} value={this.state.datiBack.NOME_AZIENDA} NomeVariable={"NOME_AZIENDA"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Slang" disabled={false} value={this.state.datiBack.SLANG} NomeVariable={"SLANG"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Codice Univoco Fatturazione" disabled={false} value={this.state.datiBack.COD_UNIVOCO_FATTURAZIONE} NomeVariable={"COD_UNIVOCO_FATTURAZIONE"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Email" disabled={false} value={this.state.datiBack.EMAIL} NomeVariable={"EMAIL"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="PEC" disabled={false} value={this.state.datiBack.PEC} NomeVariable={"PEC"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Nome Responsabile" disabled={false} value={this.state.datiBack.NOME_RESPONSABILE} NomeVariable={"NOME_RESPONSABILE"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Cognome Responsabile" disabled={false} value={this.state.datiBack.COGNOME_RESPONSABILE} NomeVariable={"COGNOME_RESPONSABILE"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Telefono Responsabile" disabled={false} value={this.state.datiBack.TELEFONO_RESPONSABILE} NomeVariable={"TELEFONO_RESPONSABILE"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Email Responsabile" disabled={false} value={this.state.datiBack.EMAIL_RESPONSABILE} NomeVariable={"EMAIL_RESPONSABILE"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Via" disabled={false} value={this.state.datiBack.VIA} NomeVariable={"VIA"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="CAP" disabled={false} value={this.state.datiBack.CAP} NomeVariable={"CAP"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Nazione" disabled={false} value={this.state.datiBack.NAZIONE} NomeVariable={"NAZIONE"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Url Logo" disabled={false} value={this.state.datiBack.URL_LOGO} NomeVariable={"URL_LOGO"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Pagato" disabled={false} value={this.state.datiBack.PAGATO} NomeVariable={"PAGATO"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Stato" disabled={false} value={this.state.datiBack.STATO} NomeVariable={"STATO"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Limite View" disabled={false} value={this.state.datiBack.LIMITE_VIEW} NomeVariable={"LIMITE_VIEW"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Tag Disponibili" disabled={false} value={this.state.datiBack.TAG_DISPONIBILI} NomeVariable={"TAG_DISPONIBILI"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Prodotti Disponibili" disabled={false} value={this.state.datiBack.PRODOTTI_DISPONIBILI} NomeVariable={"PRODOTTI_DISPONIBILI"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo Type={"date"} NomeCampo="Data Inizio Contratto" disabled={false} value={this.state.datiBack.DATA_INIZIO_CONTRATTO} NomeVariable={"DATA_INIZIO_CONTRATTO"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo Type={"date"} NomeCampo="Data Scadenza Contratto" disabled={false} value={this.state.datiBack.DATA_SCADENZA_CONTRATTO} NomeVariable={"DATA_SCADENZA_CONTRATTO"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Id Azienda Tag" disabled={true} value={this.state.datiBack.ID_AZIENDA_TAG} NomeVariable={"ID_AZIENDA_TAG"} changeValue={this.changeValueBackend}></InputCampo>
                    <InputCampo NomeCampo="Id Azienda" disabled={true} value={this.state.datiBack.ID_AZIENDA} NomeVariable={"ID_AZIENDA"} changeValue={this.changeValueBackend}></InputCampo>
                    <button className="send-modifica-btn" onClick={this.inviaDati}>Modifica Dati</button>
                </div>
            }
        </div>)
    }
}
export default Home;
