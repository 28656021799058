import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import Menu from "./Component/Menu.js";
import Home from "./Page/Home.js";
import InserisciAzienda from "./Page/InserisciAzienda.js";
import Supporto from "./Page/Supporto.js";
import Ordini from "./Page/Ordini.js";
import Template from "./Page/Template.js";

function AppHome(props) {
  async function eseguiBackup() {
    cambiaLoaderBackup();
    let result = await fetch(process.env.REACT_APP_BACKEND + '/eseguiBackup.php', {
      method: 'POST',
      mode: "cors",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(res => { return res.json() });
    settaLinkDownload(result.messaggio);
    settaFalseLoaderBackup();
  }
  const [linkDownloadBackup, setlinkDownloadBackup] = useState(
    false
  );

  const [loaderBackup, setloaderBackup] = useState(
    false
  );

  function settaLinkDownload(link) {
    setlinkDownloadBackup(link);
  }
  function settaFalseLoaderBackup() {
    setloaderBackup(false);
  }

  function cambiaLoaderBackup() {
    setloaderBackup(!loaderBackup);
  }

  return (

    <div className="container-slum">
      {loaderBackup ? <img src={process.env.PUBLIC_URL + '/loader1.gif'} /> :
        <div className="row">
          <div className="col-12 col-sm-2">
            {localStorage.getItem("token") && <Menu linkDownloadBackup={linkDownloadBackup} eseguiBackup={eseguiBackup} eseguiLogout={props.eseguiLogout}/>}
          </div>
          <div className="col-12 col-sm-10">
            <div>
              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/inserisci-azienda" component={InserisciAzienda} />
              <Route exact path="/supporto" component={Supporto} />
              <Route exact path="/ordini" component={Ordini} />
              <Route exact path="/template" component={Template} />
            </div>
          </div>
        </div>
      }
    </div >
  );
}

export default AppHome;
