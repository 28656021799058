import React from 'react';

function InputComponent(props) {
    return (
        <div>
            <label class="title-slum-tally">{props.title}</label>
            <div class="input-slum-tally">
                <input disabled={props.Disabled} type="text" className={props.check ? "input-slum-tally input-slum-tally-error" : "input-slum-tally"} placeholder={props.placeHolder} name={props.name} onChange={props.changeValue} />
            </div>
        </div>);
}
export default InputComponent;