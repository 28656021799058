import React from "react";
import { BrowserRouter as Route, Link } from "react-router-dom";
import ViewOrdine from "../Component/ViewOrdine.js";
import Ordine from "../Component/Ordine.js";

class Ordini extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            check_visualizza: false,
            check_effettuato: true,
            disableInput: false,
            check_operazione_eseguita: false
        };
        this.visualizza = this.visualizza.bind(this);
        this.leggiDati = this.leggiDati.bind(this);
        this.settaOrdini = this.settaOrdini.bind(this);
        this.ricerca = this.ricerca.bind(this);
        this.cambiaStatoOrdine = this.cambiaStatoOrdine.bind(this);
    }
    componentDidMount() {
        this.leggiDati();
    }
    async visualizza(id_ordine) {
        this.setState({
            check_operazione_eseguita: false
        })
        for (let i = 0; i < this.state.ordiniDB.length; i++) {
            if (this.state.ordiniDB[i].ID_ORDINE_TAG == id_ordine) {
                await this.setState({ ordineSelezionato: this.state.ordiniDB[i] });
                break;
            }
        }
        if (this.state.ordineSelezionato.STATO == 0) {
            await this.setState({
                check_effettuato: false,
            })
        }
        else {
            await this.setState({
                check_effettuato: true,
            })
        }
        await this.setState({
            check_visualizza: true
        })
    }
    async cambiaStatoOrdine(e) {
        let stato;
        if (e.target.name == "revisione") {
            stato = 2;
        }
        else if (e.target.name == "effettuato") {
            stato = 1
        }
        var data = new FormData();
        const payload = {
            stato: stato,
            id_ordine: this.state.ordineSelezionato.ID_ORDINE_TAG
        };
        data.append("datiMessaggio", JSON.stringify(payload));
        let result = await fetch(process.env.REACT_APP_BACKEND + '/cambiaStatoOrdine.php', {
            method: 'POST',
            body: data,
            mode: "cors",
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }).then(res => { return res.json() });
        if (result.stato == "success") {
            result = JSON.parse(result.messaggio);
            this.leggiDati();
            this.setState({
                check_operazione_eseguita: true
            })
        }
    }
    async settaOrdini(result) {
        let ordini = [];
        result.forEach(ordine => {
            ordini.push(<Ordine visualizza={this.visualizza} datiOrdine={ordine} />);
        });
        this.setState({ ordini: ordini });
    }
    async leggiDati() {
        let result = await fetch(process.env.REACT_APP_BACKEND + '/AllOrdini.php', {
            method: 'POST',
            mode: "cors",
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        }).then(function (response) {
            return response.json();
        })
        result = JSON.parse(result.messaggio);
        this.setState({ ordiniDB: result, loader: false });
        this.settaOrdini(result);
    }
    async ricerca(e) {
        let resultRicerca;
        if (e.target.value == "") {
            resultRicerca = this.state.ordiniDB;
        }
        else {
            resultRicerca = [];
            for (let i = 0; i < this.state.ordiniDB.length; i++) {
                if (this.state.ordiniDB[i].NOME_AZIENDA.toUpperCase().includes(e.target.value.toUpperCase())) {
                    resultRicerca.push(this.state.ordiniDB[i]);
                }
            }
        }
        this.settaOrdini(resultRicerca);
    }
    render() {
        if (this.state.loader) {
            return <img src={process.env.PUBLIC_URL + '/loader1.gif'}></img>
        }
        else {
            return (
                <div className="row lista-nomi-aziende">
                    <div className="col-6 animated slideInRight">
                        <input onChange={this.ricerca} className="cerca-azienda-input" placeholder="Cerca il nome dell'azienda"></input>
                        {this.state.ordini}
                    </div>
                    {
                        this.state.check_visualizza &&
                        <div className="col-6 lista-dati-aziende animated slideInRight">
                            <ViewOrdine NomeCampo="Tipo" ValoreCampo={this.state.ordineSelezionato.TIPO}></ViewOrdine>
                            <ViewOrdine NomeCampo="Numero Tag" ValoreCampo={this.state.ordineSelezionato.NUMERO_TAG}></ViewOrdine>
                            <ViewOrdine NomeCampo="Data Ordine" ValoreCampo={this.state.ordineSelezionato.DATA_ORDINE}></ViewOrdine>
                            <ViewOrdine NomeCampo="Descrizione" ValoreCampo={this.state.ordineSelezionato.DESCRIZIONE}></ViewOrdine>
                            <ViewOrdine NomeCampo="Causale" ValoreCampo={this.state.ordineSelezionato.CAUSALE}></ViewOrdine>
                            <ViewOrdine NomeCampo="Stato" ValoreCampo={this.state.ordineSelezionato.STATO}></ViewOrdine>
                            <ViewOrdine NomeCampo="Costo" ValoreCampo={this.state.ordineSelezionato.COSTO}></ViewOrdine>
                            <ViewOrdine NomeCampo="Id Ordine Tag" ValoreCampo={this.state.ordineSelezionato.ID_ORDINE_TAG}></ViewOrdine>
                            {this.state.ordineSelezionato.STATO != 2 && <button className="backup-btn button-revisione" name="revisione" onClick={this.cambiaStatoOrdine}>Revisione</button>}<br></br>
                            {this.state.ordineSelezionato.STATO == 0 && <button className="backup-btn button-effettuato" name="effettuato" onClick={this.cambiaStatoOrdine}>Effettuato</button>}<br></br>
                            {this.state.check_operazione_eseguita && <span classname="msg-operazione-eseguita" >Operazione eseguita correttamente!</span>}
                        </div>
                    }


                </div>
            );
        }
    }
}
export default Ordini;
