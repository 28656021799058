import React from "react";

function ReceivedMessage(props) {
    return (
        <div className="row row-received-message">
            <span className="received-msg-nome-azienda">
                {props.richiesta.NOME_AZIENDA}:
            </span>
            <span className="received-msg-data-invio">
                {new Date(props.messaggio.DATA_INVIO).toLocaleDateString().replace(/\//g, ".")}
            </span>
            <div className="col-12 received-message" >
                <span className="received-msg-testo">
                    {props.messaggio.TESTO}
                </span>
            </div>
        </div>
    );
}
export default ReceivedMessage;
