import React from "react";

function InputCampo(props) {
    return (
        <section>
            <label className="title-slum-tally" className="title-slum-tally">{props.NomeCampo}</label><br />
            <input  className="input-slum-tally" disabled={props.disabled} value={props.value} name={props.NomeVariable} onChange={props.changeValue} ></input><br />
        </section>
    );
}
export default InputCampo;
