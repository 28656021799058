import React from "react";

function Richiesta(props) {
    return (
        <div className="container-nomi-azienda">
            <span className="nome-azienda"><b>Nome Azienda: </b><br></br>{props.datiAzienda.NOME_AZIENDA}</span><br></br>
            <span className="nome-azienda"><b>Titolo Richiesta: </b><br></br>{props.datiAzienda.TITOLO}</span><br></br>
            <span className="nome-azienda"><b>Data: </b><br></br>{props.datiAzienda.DATA}</span>
            <br></br>
            <button className="visualizza-richieste-btn" onClick={() => { props.visualizza(props.datiAzienda.CODICE_RICHIESTA) }}>Visualizza Richieste</button>
        </div>
    );
}
export default Richiesta;