import React from "react";

function SendMessage(props) {
    return (
        <div className="row row-received-message">
            <span className="row send-msg-data-invio">
                {new Date(props.messaggio.DATA_INVIO).toLocaleDateString().replace(/\//g, ".")}
            </span>
            <span className="bold" className="send-msg-tally" >Bee:</span>
            <div className="col-12 send-message">
                <span className="send-msg-testo">
                    {props.messaggio.TESTO}
                </span>
            </div>
        </div>
    );
}
export default SendMessage;
