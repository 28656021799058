import React from "react";
import { BrowserRouter as Route, Link } from "react-router-dom";

function Menu(props) {
    return (
        <React.Fragment id="menu-sx">
            <Link to="/"><img className="logo" src={'https://dashboard.tally-app.com/Tally-Logo-512.png'}></img></Link>
            <br></br>
            <Link className="voce-menu" to="/">Clienti</Link> <br></br>
            <Link className="voce-menu" to="/inserisci-azienda">Inserisci Cliente</Link><br></br>
            <Link className="voce-menu" to="/supporto">Richieste supporto</Link><br></br>
            <Link className="voce-menu" to="/template">Template</Link><br></br>
            {false && <button onClick={props.eseguiLogout} className="voce-menu btn-logout">Esegui Logout</button>}
            <button onClick={()=>{
                localStorage.removeItem("token");
                window.location.reload();
            }} className="backup-btn" style={{color:"red",border:"1px solid red"}}>Logout</button><br></br>
        </React.Fragment>);
}
export default Menu;