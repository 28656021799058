import React from "react";

function Ordine(props) {
    return (
        <div className="container-nomi-azienda">
            <span className="nome-azienda"><b>Nome Azienda: </b><br></br>{props.datiOrdine.NOME_AZIENDA}</span><br></br>
            <span className="nome-azienda"><b>Data Ordine: </b><br></br>{props.datiOrdine.DATA_ORDINE}</span><br></br>
            <button className="visualizza-richieste-btn" onClick={() => { props.visualizza(props.datiOrdine.ID_ORDINE_TAG) }}>Visualizza Ordine</button>
        </div>
    );
}
export default Ordine;